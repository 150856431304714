<template>
	<div>
		<a href="javascript:history.back()" class="btn btn-outline-secondary mr-2 mb-2" v-if="showBackLink">
			<font-awesome-icon icon="arrow-left"/>
			{{ $t('wizard.prev') }}
		</a>

		<h1>General terms and conditions</h1>

		<p>The user (hereinafter referred to as <strong>&quot;User&quot;</strong>) who registers in the <strong>PilotNet</strong> smartphone application (hereinafter referred to as <strong>&quot;Application&quot;</strong>) at PilotNet Kft. (hereinafter referred to as<strong>&quot;Service Provider&quot;</strong>) and the user of the map information available from the browser (hereinafter referred to as <strong>&quot;Occasional User&quot;</strong>) accepts the terms and conditions of these General Terms and Conditions and acknowledges them as binding. The Application and the browser-based information hereinafter referred to as <strong>&quot;Services&quot;.</strong></p>

		<p>If the User does not agree to the Application of these General Terms and Conditions (hereinafter referred to as <strong>&quot;GTC&quot;</strong>), the Application, the browser-based Map and flight preparation services shall not be used and shall not be available.</p>

		<h1>Disclaimer:</h1>

		<p>Before using the Application, it is mandatory for the User to read the following statement. By using the Application, the User accepts and acknowledges the following as binding:</p>

		<p><em>&quot;This is a non-certified software that is aiming to provide assistance for pilots flying within the Hungarian airspace. The system is the result of information provided by a non-structured community of pilots and ground stations and other aviation-related service providers available on the internet. In no way PilotNet can guarantee the accuracy nor comprehensiveness of data nor permanent data transmission while using the &quot;Services&quot;.</em></p>

		<p><em>The purpose of the &quot;Application&quot; is to remind you of your duty to apply the principle of &#39;See and Avoid&#39;: to look out at all times when flying. It must never be used in IMC flight.</em></p>

		<p><em>In no way PilotNet can be held liable for any damages arising from the use of the &quot;Services.&quot;</em></p>

		<p><em>ATC and official information prevail. The information provided by the &quot;Services&quot; is not intended to replace the information provided in the sky, such as travel directions, time-based restrictions, police instructions, etc.</em></p>

		<p><em>The information provided by PilotNet originates from other users of the &quot;Application&quot; and third parties. Such information is intrinsically fluctuant and may be inaccurate, incomplete, or outdated. PilotNet does not provide any warranties regarding such information&#39;s credibility or reliability.</em></p>

		<p><em>Some features of the &quot;Application&quot; make use of detailed location and route information, for example in the form of GPS signals and other information sent by your mobile device on which the PilotNet application is installed and activated.</em></p>

		<p><em>Some features of the &ldquo;Application&rdquo; require an Internet connection. Any associated charges (e.g. mobile data expenses) incurred by your use of the &quot;Application&quot; are your exclusive responsibility and made solely at your expense. Transmitting and receiving real-time updates to and from the &quot;Application&quot;, requires an online (e.g. Wi-Fi, 3G, 4G, 5G) connection between your cellular device and the Internet. The expenses of such connection are as prescribed by the agreement between you and your communication service provider (such as your cellular company), and according to its applicable terms of payment.</em></p>

		<p><em>Registration before using the &ldquo;Application&rdquo; is mandatory.&rdquo;</em></p>

		<p>&nbsp;</p>

		<h2>1. Service provider details:</h2>

		<p>The owner&nbsp; of the Application and the <a href="https://pilotnet.hu" target="_blank">https://pilotnet.hu</a> Website - where the flight planning services are available from the browser- is the Service Provider, the operator is Ready 2 Grow Kft.</p>

		<p>&nbsp;</p>

		<p>Name: PilotNet Kft.<br />
			Registered seat: 2100 G&ouml;d&ouml;llő, Esze Tam&aacute;s utca 3.<br />
			Company registration number: 13-09-227621<br />
			Tax number: 32298181-2-13</p>

		<p>Name: Ready 2 Grow Kft.<br />
			Registered seat: 2100 G&ouml;d&ouml;llő, Remsey krt. 8.<br />
			Company registration number: 13-09-177610<br />
			Tax number: 25397130-2-13</p>

		<p><br />
			Telephone Customer Service Contact: +36 20 367 84 07<br />
			Website address of Ready 2 Grow Kft.: <a href="https://r2g.hu" target="_blank">https://r2g.hu</a><br />
			E-mail address of Ready 2 Grow Kft.: <a href="mailto:info@r2g.hu">info@r2g.hu</a></p>

		<p>&nbsp;</p>

		<h2>2. Registration to use the service</h2>

		<h3>a) Service Provider Application</h3>

		<p>The services provided by the Service Provider are available via the PilotNet application for iOS and Android operating systems (registration is required) and via the PilotNet website (<a href="https://pilotnet.hu" target="_blabk">https://pilotnet.hu</a>).</p>

		<p>The Application can be downloaded and registered by anyone.</p>

		<p>To start using the Application, the User shall create a personal user account in the Application, read and accept the GTC and the privacy policy.</p>

		<p>Registration is free of charge.</p>

		<p>The registration will be confirmed by e-mail.</p>

		<p>The Service Provider will endeavor to provide the User with a continuous and uninterrupted service, but cannot guarantee that the Service will be available, continuous, acute or error-free in all cases. The User may access the Application using his/her Google or Facebook account.</p>

		<h3>b) The contract between the User and the Service Provider is concluded</h3>

		<ul>
			<li>by the User downloading the Service Provider&#39;s Application on his/her mobile phone and successfully registering in the Application, or</li>
			<li>by means of a registration, initiated on the Website.</li>
		</ul>

		<h3>c) Technical steps for the conclusion of the contract:</h3>

		<h4>Using the Application:</h4>

		<ul>
			<li>Download the Application from the App Store or Google Play Store</li>
			<li>Install the Application</li>
			<li>Click on the login button on the main screen</li>
			<li>Enter and confirm your email address and password. The password must be at least 8 characters long and contain upper- and lowercase letters and a number</li>
			<li>download and read the privacy policy and the general terms and conditions;</li>
			<li>click on the button next to the general terms and conditions and the privacy policy to confirm that you understand and accept them;</li>
		</ul>

		<p>&nbsp;</p>

		<h4>Using the Web browser:</h4>

		<ul>
			<li>opening the website</li>
			<li>click on the button to start charging</li>
			<li>click on the login button on the main screen</li>
			<li>enter and confirm your email address and password. The password must be at least 8 characters long and contain upper- and lowercase letters and a number</li>
			<li>download and read the privacy policy and the general terms and conditions;</li>
			<li>click on the button next to the general terms and conditions and the privacy policy to confirm that you understand and accept them;</li>
		</ul>

		<p>By registering online, the User accepts that the Service Provider will provide the services specified in these GTC online and that any information related to these services will be sent by the Service Provider to the registration e-mail address provided by the User. If the Service Provider decides to modify the GTC, the content of the modification will be sent to the User&#39;s registered email address or the modification of the GTC will be indicated in the Application, with the insertion of the current version. By using the System, the User also accepts any amendments to the GTC.</p>

		<p>During the registration process, the User is obliged to provide the real data relating to him/her. If the Service Provider becomes aware that the data provided by the User (also) contains elements that are not real, the Service Provider is entitled to restrict access to the Application in whole or in part or to exclude the User from using the Application.</p>

		<p>The Service Provider reserves the right to refuse registration without giving any specific reason or to withdraw it temporarily or permanently at any time, in particular in the following cases:</p>

		<ul>
			<li>in case of false or incomplete data provided during registration.</li>
			<li>if the User has previously registered and whose contract has been terminated by the Service Provider for breach of contract.</li>
		</ul>

		<p>The User is obliged to ensure the functioning (maintenance) of the online contact details (e-mail address) registered by him/her. The Service Provider shall not be liable for any damage resulting from the provision of incorrect or non-functional online contact details (e-mail address), and the User shall be liable for any obligations towards the Service Provider.</p>

		<p>The Service Provider informs and reminds the User to change his/her password regularly and not to give it to anyone or store it in a place accessible to others. The safe storage of the password and the provision of the current online contact information (e-mail address) suitable for notifying the User is mandatory even when using the Application and is an event in the User&#39;s interest, the Service Provider shall not be liable for any damages resulting from the failure to do so, and the failure to do so shall not relieve the User from the performance of its obligations towards the Service Provider.</p>

		<p>The User shall be obliged to modify and update the data stored in the Profile and Invoicing Data sections of the application and the website in the event of any changes to his/her data.</p>

		<h3>d) Forgotten password</h3>

		<p>Before logging into the Application, the User can request a new password using the &quot;Forgot password&quot; function. The system will automatically send a message to the e-mail address provided at registration, which will contain a link to the Website or interface. By clicking on this link, the User can give a new password. In the event of an unsuccessful password change, the User may contact the Service Provider&#39;s Customer Service at <a href="mailto:info@freecharger.hu">info@freecharger.hu</a> for assistance.</p>

		<h3>e) Profile details</h3>

		<p>The User can change his/her telephone number, email address and password used to log in by going to Profile. In the Profile menu you can also delete your profile. By deleting the profile, the user account is terminated and all personal data is irrevocably deleted. If the User has charged and has successfully completed the payment in the application or via the website, the invoicing data of this User will continue to be stored for tax purposes in accordance with the legislation in force until the legally required deadline. Once deleted, the deleted User account will no longer be logged in.</p>

		<h3>f) Password change</h3>

		<p>If the User is intended to change his/her password, he/she may enter a new password after logging in to the Application or the Website. An automatic confirmation of a successful password change will be sent. In case of unsuccessful password change, the User may contact the Service Provider&#39;s Customer Service for assistance.</p>

		<h2>3. Obligations of the User</h2>

		<p>While flying, it is the User&rsquo;s responsibility to always follow the applicable rules of flying. The Service provider cannot be held responsible for failing to comply with any of these rules.</p>

		<p>The IT content of the Service is protected by copyright, trademark and/or other intellectual property rights.</p>

		<p>The Service Provider is the owner of all individual and proprietary copyrights in the Application as a copyright work in the form of computer programs. By accepting these GTC, the Service Provider authorizes the User to make non-exclusive, limited use of the Application.</p>

		<p>The User shall be entitled to use the Service only to the minimum extent necessary to enforce its rights and obligations under the present GTC.</p>

		<p>The User is not entitled to:</p>

		<ul>
			<li>revise, modify, copy, reproduce, sell, transfer by any means, or decrypt codes in the Application.</li>
			<li>use the Services commercially</li>
			<li>use, copy, mirror, reproduce or forward the Content provided to the User by the Services without prior written consent from the Service Provider</li>
			<li>create a database by downloading and storing content from Services</li>
		</ul>

		<p>In the event of unlawful use of the Program, the Service Provider shall be entitled to claim damages and to initiate the appropriate proceedings to enforce the civil and criminal law consequences provided for in Act LXXVI of 1999 on Copyright.</p>

		<h2>4. Obligations of the service provider</h2>

		<p>The Service Provider shall not be liable for any damages resulting from the User&#39;s improper use of the Application, the Service or the means necessary to use them, or from the User&#39;s failure to exercise due care in selecting the appropriate secure environment in which to use the Services. The Service Provider shall not be liable for any damage resulting from viruses or other similar infections of the User&#39;s computer system (including any smartphone).</p>

		<p>The Service Provider shall not be liable for any damage caused by a cause beyond the Service Provider&#39;s control or for any cause not in the interest of the Service Provider, in particular for any failure of telecommunication lines, Internet connection, data transmission systems or postal services.</p>

		<p>The Service Provider shall not be liable for any damage which the Service Provider is unable to prevent or influence, in particular damage resulting from force majeure, the orders of a national or foreign authority or the disruption of the Service Provider&#39;s operations, nor shall the Service Provider be liable for any damage if the Service Provider temporarily suspends or limits the Application, the Service or the access to or provision of the Service.</p>

		<p>The Service Provider shall not be liable for any events, circumstances, damages or other consequences that may result from:</p>

		<ul>
			<li>the fault of the User;</li>
			<li>failure of the User&#39;s mobile device or inability to use the Application;</li>
			<li>the failure of the operating system on the User&#39;s mobile device or the communication link used by the User;</li>
			<li>improper use of the Application or use otherwise in breach of these GTC;</li>
			<li>the non-receipt by the User&#39;s mail system of automatic (informative) system messages which can be proven to have been sent by the Application.</li>
		</ul>

		<p>The Service Provider does not assume any responsibility for the safe storage of the User&#39;s password or for any damage resulting from this.</p>

		<p>In addition to the foregoing, the Service Provider shall have no other warranties or other obligations for the accuracy, reliability, operation, completeness, fitness for a particular purpose or expectations, suitability, and any direct or indirect damages or other legal consequences arising out of or resulting from the lack thereof, the use of the Application. Furthermore, the Service Provider shall not be liable for any errors and their consequences arising from causes beyond its control (such as technical failures or power failures in the Internet network).</p>

		<p>By accepting these GTC, the parties agree in accordance with the provisions of Section 6:152 of the Civil Code, the Service Provider shall be liable for damages caused to the User in connection with the use of the Website and the Application, except for damages caused intentionally or to human life, bodily injury or health, up to the amount of the consideration for the Service affected by the event. The Parties further agree that the Service Provider&#39;s liability shall be limited to damages directly related to the activity specified in the Contract, and shall not cover indirect, unforeseeable, consequential damages or damages arising from the use of software provided by third parties.</p>

		<h2>5. PilotNet Pro</h2>

		<p><b>PilotNet Pro</b> is the term used to summarize the features which are extending the normal PilotNet system capabilities, however, these are requiring subscription.</p>

		<h3>a) Subscription, Payments</h3>

		<h4>Free Trial Period</h4>

		<ul>
			<li>PilotNet Pro membership starts with a free trial. The free trial period of the membership is intended to allow new members and certain former members to try the service.</li>
			<li>The Service Provider determines free trial eligibility at its sole discretion, and may limit eligibility to prevent free trial abuse. We reserve the right to revoke the free trial and put any account on hold if we determine that the user is not eligible.</li>
			<li>Trial accounts with no active subscription might be deleted after the trial period is finished</li>
			<li>We may use information such as device ID or an account email address used with an existing or recent membership to determine eligibility. For combinations with other offers, restrictions may apply.</li>
		</ul>

		<h4>Subscription</h4>

		<ul>
			<li>Subscriptions can be either renewable type, or based on one-time payments</li>
			<li>The amount of the initial and recurring charge are shown on the PilotNet Pro website at the time of subscribing. The rate of each recurring charge may change in the future, with at least 30 days notice of any change. Notice of subscription changes will be sent to the email address held on your account.</li>
			<li>Subscriptions can be cancelled at any time. Your subscription will continue until the end of the currently billed period. No refunds will be provided for the remaining or unused billing period. Subscriptions can be cancelled by deleting the user account.</li>
			<li>Renewable subscription option can be disabled from “Profile” menu</li>
		</ul>

		<h3>b) Obligations of the User</h3>

		<ul>
			<li>Users are forbidden to save and export non-existing flights in the system (flights without real aircraft or flight simulators for pilot training).</li>
			<li>Users are responsible for regularly backing up their important data, information, and content, including notes, settings, and preferences within the Service. To prevent potential data loss, Service Provider strongly recommends performing regular backups. Please note that the Service Provider has no obligation to archive or create backup copies of any data of the records or save them.</li>
			<li>Using the information contained in the Application is at own risk. The Service Provider cannot be held responsible for any damage and/or loss of life caused directly or indirectly by using the information stored or exported from the Application.</li>
		</ul>

		<h2>6. Termination by the User</h2>

		<p>These GTC may be terminated by the User at any time, with immediate effect, by deleting the registration.</p>

		<p>Upon deleting the registration, the User&#39;s Application profile will be automatically deleted. The Service Provider shall be entitled to store the receipts and invoices of already completed Charging Transactions until the expiry of the general limitation period.</p>

		<h2>7. Termination by the Service Provider</h2>

		<p>The Service Provider shall be entitled to terminate this Agreement with immediate effect, subject to the obligation to state reasons, if the User uses the Service in a manner that is contrary to this Agreement. The Service Provider may also terminate this Agreement if the Service Provider has reasonable grounds to suspect that the User is using the Service inappropriately.</p>

		<p>The Service Provider may terminate this Agreement by giving one (1) month&#39;s notice, effective upon the expiry of the existing subscription (if applicable).</p>

		<p>&nbsp;</p>

		<h2>8. Processing of personal data, security</h2>

		<p>The Service Provider shall be entitled to store, process and use the personal data provided by the User during registration in the Application or on the Webpage or at any time thereafter, in connection with the Service, as set out in the Service Provider&#39;s Privacy Policy. The User is obliged to make all reasonable efforts to protect his/her personal data, in particular, to protect the User&#39;s name, e-mail address, and, in the case of both online interfaces, the password, in order to use the services provided by the Service Provider. The User shall be liable for any event, activity, or omission that occurs through the use of his/her username or password.</p>

		<h2>9. Other provisions</h2>

		<p>The present contract is a written contract between the Service Provider and the User. The Service Provider shall record the present contract and any amendments thereto, which shall be available on the Service Provider&#39;s website. The official language of this contract is Hungarian. The present GTC may be amended unilaterally by the Service Provider. The Service Provider shall publish the amendment on its website 15 days prior to its entry into force. The Service Provider shall publish the current version of the GTC on its website.</p>

		<p>&nbsp;</p>

		<p>14.2 The invalidity of any provision of these GTC shall not affect the validity and effect of the other provisions, and in the event of severability, the other parts of the contract shall remain in force unchanged.</p>

		<p>&nbsp;</p>

		<p>14.3 In matters not regulated in these GTC, the laws of Hungary, in particular Act V of 2013 on the Civil Code, Act CXII of 2011 on the Right of Informational Self-Determination and on freedom of Information, and the provisions of the legislation in force affecting aviation shall apply.</p>

		<p>&nbsp;</p>
	</div>
</template>

<script>
export default {
	name: "GtcView",
	data() {
		return {
			showBackLink: history.length > 1
		}
	},
	methods: {
		scrollTo(refName) {
			let element = this.$refs[refName];
			let top = element.offsetTop;
			window.scrollTo(0, top);
		}
	}
}
</script>

<style scoped>
table {
	border-top: 1px solid #000000;
	border-left: 1px solid #000000;
	border-spacing: 0;
	width: 100%;
	margin-bottom: 1rem;
}

td, th {
	border-bottom: 1px solid #000000;
	border-right: 1px solid #000000;
	padding: 5px;
	text-align: left;
}

tbody th {
	font-weight: normal;
}

ol {
	counter-reset: item;
}

ol > li {
	counter-increment: item;
}

ol ol > li {
	display: block;
}

ol ol > li:before {
	content: counters(item, ".") ". ";
	margin-left: -20px;
}
</style>
